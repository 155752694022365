import { render, staticRenderFns } from "./Tabs2.vue?vue&type=template&id=6d4ab1bf&scoped=true&"
import script from "./Tabs2.vue?vue&type=script&lang=js&"
export * from "./Tabs2.vue?vue&type=script&lang=js&"
import style0 from "./Tabs2.vue?vue&type=style&index=0&id=6d4ab1bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d4ab1bf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VIcon,VProgressCircular,VProgressLinear,VRadio,VRadioGroup,VRating,VSelect,VSubheader,VTextField})
